define("@adopted-ember-addons/ember-stripe-elements/components/stripe-postal-code", ["exports", "@ember/component", "@ember/template-factory", "@adopted-ember-addons/ember-stripe-elements/components/stripe-element"], function (_exports, _component, _templateFactory, _stripeElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='ember-stripe-element ember-stripe-postal-code'>
    {{! template-lint-disable no-invalid-role }}
    <div
      role='mount-point'
      {{did-insert this.registerListeners}}
      {{did-update this.onOptionsChange}}
    ></div>
    {{! template-lint-enable no-invalid-role }}
    {{#if (has-block)}}
      {{yield this.stripeElement this.stripeError}}
    {{/if}}
  </div>
  */
  {
    "id": "T2dWq0/x",
    "block": "[[[10,0],[14,0,\"ember-stripe-element ember-stripe-postal-code\"],[12],[1,\"\\n\"],[1,\"  \"],[11,0],[24,\"role\",\"mount-point\"],[4,[38,0],[[30,0,[\"registerListeners\"]]],null],[4,[38,1],[[30,0,[\"onOptionsChange\"]]],null],[12],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[30,0,[\"stripeElement\"]],[30,0,[\"stripeError\"]]]],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"did-insert\",\"did-update\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@adopted-ember-addons/ember-stripe-elements/components/stripe-postal-code.hbs",
    "isStrictMode": false
  });
  class StripePostalCode extends _stripeElement.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "type", 'postalCode');
    }
  }
  _exports.default = StripePostalCode;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StripePostalCode);
});