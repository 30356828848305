define("@ember/-internals/overrides/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onEmberGlobalAccess = void 0;
  var onEmberGlobalAccess;
  _exports.onEmberGlobalAccess = onEmberGlobalAccess;
});
