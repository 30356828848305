define("@ember/-internals/views/lib/compat/attrs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MUTABLE_CELL = void 0;
  var MUTABLE_CELL = Symbol('MUTABLE_CELL');
  _exports.MUTABLE_CELL = MUTABLE_CELL;
});
